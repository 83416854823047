import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export interface SendCodeRequest {
  phone: string;
}

export interface VerifyCodeRequest {
  phone: string;
  code: string;
}

export interface VerifyCodeResponse {
  token: string;
}

export interface ChannelInfoResponse {
  id: string;
  channel_id: number;
  landing_page_url: string;
  created_at: string;
  updated_at: string;
}

export const sendVerificationCode = async (request: SendCodeRequest): Promise<void> => {
  await axios.post(`${API_BASE_URL}/auth/send-sms`, request, { withCredentials: true });
};

export const verifyPhone = async (request: VerifyCodeRequest): Promise<string> => {
  const response = await axios.post<VerifyCodeResponse>(`${API_BASE_URL}/auth/verify-sms`, request, { withCredentials: true });
  return response.data.token;
};

export const getChannelInfo = async (token: string): Promise<ChannelInfoResponse> => {
  const response = await axios.post<ChannelInfoResponse>(
    `${API_BASE_URL}/channel-info`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true
    }
  );
  return response.data;
};