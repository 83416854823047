import React from 'react';
import PhoneVerification from './components/PhoneVerification';

function App() {
  return (
    <div className="App">
      <PhoneVerification />
    </div>
  );
}

export default App;
